import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  NavbarText,
  Row,
  Spinner,
} from 'reactstrap';

import fetch from '../../utils/fetch';
import { ReactComponent as BlackLogo } from '../../assets/B.svg';
import { ReactComponent as ColorlessLogo } from '../../assets/C.svg';
import { ReactComponent as GreenLogo } from '../../assets/G.svg';
import { ReactComponent as RedLogo } from '../../assets/R.svg';
import { ReactComponent as BlueLogo } from '../../assets/U.svg';
import { ReactComponent as WhiteLogo } from '../../assets/W.svg';

import './App.css';

function App() {
  const [selected, setSelected] = useState<string[]>([]);
  const [cmdrImg, setCmdrImg] = useState<string>('');
  const [cmdrName, setCmdrName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const shuffle = async () => {
    setLoading(true);
    setCmdrImg('');
    setCmdrName('');
    const data = await fetch.fetchCommander(selected);
    setCmdrImg(data.image_uris.normal);
    setCmdrName(data.name);
  };

  const clear = () => {
    setLoading(true);
    setCmdrImg('');
    setCmdrName('');
    if (selected.length > 0) {
      setSelected([]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch.fetchCommander(selected);

      setCmdrImg(data.image_uris.normal);
      setCmdrName(data.name);
      setLoading(false);
    };

    fetchData();
  }, [selected]);

  const onCheckboxColorlessClick = () => {
    setLoading(true);
    setCmdrImg('');
    setCmdrName('');
    setSelected(['C']);
  };

  const onCheckboxBtnClick = (s: string) => {
    setLoading(true);
    setCmdrImg('');
    setCmdrName('');
    let chosen: string[];
    if (JSON.stringify(selected) === JSON.stringify(['C'])) {
      chosen = [];
    } else {
      chosen = [...selected];
    }
    const index: number = chosen.indexOf(s);
    if (index < 0) {
      chosen.push(s);
    } else {
      chosen.splice(index, 1);
    }
    setSelected([...chosen]);
  };

  return (
    <>
      <Navbar color="dark" dark expand="md">
        <NavbarBrand href="/">Rand Cmdr</NavbarBrand>
        <NavbarText>Get your random commander here!</NavbarText>
      </Navbar>
      <Container className="text-center mt-2">
        <Row className="d-flex justify-content-between">
          <ButtonGroup size="lg">
            <Button
              outline
              onClick={() => onCheckboxBtnClick('W')}
              active={selected.includes('W')}
            >
              <WhiteLogo width={30} height={30} />
            </Button>
            <Button
              outline
              onClick={() => onCheckboxBtnClick('U')}
              active={selected.includes('U')}
            >
              <BlueLogo width={30} height={30} />
            </Button>
            <Button
              outline
              onClick={() => onCheckboxBtnClick('B')}
              active={selected.includes('B')}
            >
              <BlackLogo width={30} height={30} />
            </Button>
            <Button
              outline
              onClick={() => onCheckboxBtnClick('R')}
              active={selected.includes('R')}
            >
              <RedLogo width={30} height={30} />
            </Button>
            <Button
              outline
              onClick={() => onCheckboxBtnClick('G')}
              active={selected.includes('G')}
            >
              <GreenLogo width={30} height={30} />
            </Button>
            <Button
              outline
              onClick={() => onCheckboxColorlessClick()}
              active={selected.includes('C')}
            >
              <ColorlessLogo width={30} height={30} />
            </Button>
          </ButtonGroup>
          <ButtonGroup size="lg" className="pl-5">
            {loading ? (
              <Button disabled color="warning">
                <Spinner />
              </Button>
            ) : (
              <Button onClick={shuffle} color="warning">
                Shuffle
              </Button>
            )}
            <Button onClick={clear} color="danger">
              Clear
            </Button>
          </ButtonGroup>
        </Row>
        <Row className="imageContainer">
          <Col>
            <h2 className="mt-2">Your Random Commander</h2>
            <h3>{cmdrName || <Spinner />}</h3>
            {cmdrImg ? (
              <img
                src={cmdrImg}
                className="img-fluid"
                alt="commander"
                onLoad={() => setLoading(false)}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Container>
      <footer className="footer mt-auto py-3">
        <Container className="d-flex justify-content-between">
          <span className="text-muted">
            &copy; 2020
            {'   '}
            <a href="https://www.joshfinnie.com/" target="_blank" rel="noopener noreferrer">Josh Finnie</a>
          </span>
          <span className="text-muted text-right">
            All images sourced from
            {' '}
            <a href="https://scryfall.com" target="_blank" rel="noopener noreferrer">Scryfall</a>
            .
          </span>
        </Container>
      </footer>
    </>
  );
}

export default App;
