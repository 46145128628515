const URL = 'https://api.scryfall.com/cards/random?q=is%3Acommander';

const fetchCommander = async (colors: string[]) => {
  let url;
  if (colors.length > 0) {
    url = `${URL}%20id%3D${colors.join('')}`;
  } else {
    url = URL;
  }
  const response = await fetch(url);
  const data = response.json();
  return data;
};

export default {
  fetchCommander,
};
